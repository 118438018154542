
import PropTypes from "prop-types"
import React from "react"
import Bandeau from "../components/bandeau"
import Image from "react-bootstrap/Image"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


function getPicturePath(imgName) {
    const images = require.context('../images/', true);
    return images('./' + imgName)
}

const Massage = ({ title, urlid, dark, content, picture, time }) => (

    <Bandeau title={title} dark={dark} urlid={urlid}>
        <Container >
            <Row className="align-items-center">
                <Col sm lg={4}>
                    <Image src={getPicturePath(picture)} rounded width="100%" className="mb-3 mt-3" />
                </Col>
                <Col sm lg={8} className="justified pl-3">
                    <div className="justified">
                        {content}
                    </div>
                    <br></br>
                    <div className="darkText">
                        Durée du massage: {time}
                    </div>
                    
                </Col>
            </Row>
        </Container>
    </Bandeau>

)

Massage.propTypes = {
    siteTitle: PropTypes.string,
}

Massage.defaultProps = {
    title: ``,
}

export default Massage
