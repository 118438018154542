import React from "react"
import Layout from "../components/layout"

import SEO from "../components/seo"
import Massage from "../containers/massage"

import { useStaticQuery, graphql } from "gatsby"

export default function MassagePage() {

  const data = useStaticQuery(
    graphql`
    query {
        allMarkdownRemark(sort: {fields: frontmatter___position}) {
          totalCount
          edges {
            node {
              id
              tableOfContents
              frontmatter {
                subtitle
                title
                prix
                dur_e
                image
                urlid
                range
              }
              excerpt
              html
              rawMarkdownBody
            }
          }
        }
      }
    `
  )

  const massages = data.allMarkdownRemark.edges
  const m0 = massages[0].node
  const m1 = massages[1].node
  const m2 = massages[2].node
  const m3 = massages[3].node
  const m4 = massages[4].node
  const m5 = massages[5].node
  const m6 = massages[6].node

  return (
    <Layout>
      <SEO title="Prestations" />
      <Massage picture={m0.frontmatter.image} urlid={m0.frontmatter.urlid} title={m0.frontmatter.title} content={m0.rawMarkdownBody} time={m0.frontmatter.range} dark />
      <Massage picture={m1.frontmatter.image} urlid={m1.frontmatter.urlid} title={m1.frontmatter.title} content={m1.rawMarkdownBody} time={m1.frontmatter.range} />
      <Massage picture={m2.frontmatter.image} urlid={m2.frontmatter.urlid} title={m2.frontmatter.title} content={m2.rawMarkdownBody} time={m2.frontmatter.range} dark />
      <Massage picture={m3.frontmatter.image} urlid={m3.frontmatter.urlid} title={m3.frontmatter.title} content={m3.rawMarkdownBody} time={m3.frontmatter.range} />
      <Massage picture={m4.frontmatter.image} urlid={m4.frontmatter.urlid} title={m4.frontmatter.title} content={m4.rawMarkdownBody} time={m4.frontmatter.range} dark />
      <Massage picture={m5.frontmatter.image} urlid={m5.frontmatter.urlid} title={m5.frontmatter.title} content={m5.rawMarkdownBody} time={m5.frontmatter.range} />
      <Massage picture={m6.frontmatter.image} urlid={m6.frontmatter.urlid} title={m6.frontmatter.title} content={m6.rawMarkdownBody} time={m6.frontmatter.range} dark />
      {/* https://getbootstrap.com/docs/4.5/utilities/stretched-link/ */}
    </Layout>

  )
}
